import './Footer.css'
const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__copy">
                &copy; 2022 Iridescent. All rights reserved.
            </div>
        </div>
      );
}
 
export default Footer;